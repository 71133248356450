import { i18n } from "@/plugins/i18n";

export default [
  {
    text: i18n.t("currency.codes.TRY"),
    value: "TRY",
    icon: "mdi-currency-try",
    symbol: "₺"
  },
  {
    text: i18n.t("currency.codes.USD"),
    value: "USD",
    icon: "mdi-currency-usd",
    symbol: "$"
  },
  {
    text: i18n.t("currency.codes.EUR"),
    value: "EUR",
    icon: "mdi-currency-eur",
    symbol: "€"
  }
];
